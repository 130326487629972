.canceled {
  text-decoration: line-through;
  font-weight: bold;
}

.invoice-header {
  height: 6em;
  line-height: 6em;
}

.tiny {
  font-size: 10px;
  font-weight: 100;
}

.invoice-title {
  margin-top: 0;
  margin-left: 1.3em;
}

$div-color: #FFFFFF;

.invoice-body {
  background-color: $div-color;
  margin-top: 2em;
  padding: 2em;
  border-radius: .8em;
}

.invoice-column-head {
  p {
    font-weight: bold;
    font-size: 1.3em;
  }
}

.search-button {
  margin-top: 1em;
}

.vertical-center {
  height: 2em;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.title_invoiceline {
  font-size: 1.2em;
  vertical-align: top;
  color: rgb(114,180,163);
}

.invoice-classic {
  margin-left: 3.5em;
}

.advance-line {
  margin-bottom: .6em;
}

.invoice-description {
  margin-left: 6em;
  width: 95%;
}

.invoice-total {
  background-color: $div-color;
  margin-top: 1em;
  margin-bottom: 4em;
  padding: 2em;
  border-radius: .8em;
}

.invoice-edit-pc-deadline {
  margin-top: 1em;
}

.sortable-element {
  background-color: $div-color;
  border-radius: .8em;
  padding: .5em;
}

.add-element {
  cursor: pointer !important;
  margin-right: 0.5em;
}

.sort-cursor {
  display: inline-block;
  float: left;
  &:hover {
    cursor: row-resize;
  }
}

.placeholder {
  background-color: #ccff90;
  border-radius: .8em;
  height: 60px;
  -webkit-box-shadow: 0px 0px 10px #888;
  -moz-box-shadow: 0px 0px 10px #888;
  box-shadow: 0px 0px 10px #888;
}

.invoice-advance {
  margin-top: 3em;
}

[contenteditable="true"] {
  padding: .3em;
  border: 1px solid grey;
  background-color: #eceff1;
  border-radius: .2em;
  min-height: 2em;
  &:hover {
    cursor: text;
  }
}

.notification {
  display: inline-block;
  color: red;
}

.space-left {
  margin-left: 2em;
}

.is-send {
  background-color: #58C9B9;
}

.invoice-table td {
  vertical-align: middle !important;
}

.form-inline {
  display: inline;
}

.search-info {
  border-radius: .8em;
  padding: 0.8em;
  margin-top: 15px;
  background-color: $div-color;
}

.clickable-row {
  min-width: 80px;
}

.invoice-clickable-row {
  min-width: 80px;
}

.invoice-settlement {
  .edit-settlement {
    vertical-align: top;
  }
}

