// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import './includes.scss';

* {
  box-sizing: border-box;
}

html {
  height: 100%
}

body {
  background-color: $background-color;
  height: 100%;
  margin: 0;
  padding: 0;
}

#piechart {
  height: 300px;
}

.grey {
  color: grey;
}

.red {
  color: red;
}

.green {
  color: green;
}

.custom-alert-message {
  position: fixed;
  display: table;
  table-layout: fixed;
  z-index: 1;
  width: 300px;
  right: -400px;
  top: 50px;
  margin-bottom: 0;
  overflow: auto;
  word-wrap: break-word;
  opacity: 0;
  transition: right 700ms ease 250ms, opacity 700ms ease;
  &.show {
    right: 10px;
    opacity: 1;
    transition: right 700ms ease, opacity 700ms ease 250ms;
  }
  .icon, .text {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
  }
  .icon {
    width: 25px;
    padding-right: 10px;
    font-size: 25px;
    &:before {
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: normal;
      vertical-align: middle;
    }
  }
  &.alert-success {
    .icon {
      &:before {
        content: "\e089";
      }
    }
  }
  &.alert-danger {
    .icon {
      &:before {
        content: "\e088";
      }
    }
  }
  &.alert-info {
    .icon {
      &:before {
        content: "\e086";
      }
    }
  }
  &.alert-warning {
    .icon {
      &:before {
        content: "\e107";
      }
    }
  }
}

.turnover-chart-container {
  background-color: $div-color;
  padding: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

.welcome-page-table {
  td {
    vertical-align: middle !important;
  }
}