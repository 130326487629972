.form-signin {
  background-color: $div-color;
  padding: 20px;
  border-radius: .8em;
  width: 40%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h2 {
    margin-top: 0px;
  }
  #username {
    margin-bottom: .5em;
  }
}