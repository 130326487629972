.client-header {
  float: none;
  margin: 0 auto;
  padding: 1em;
  background-color: #566270;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: .8em;
  h4 {
    color: #FFFFF3;
    font-weight: bold;
  }
}

.client-tva-edit {
  background-color: #F1F1F1;
}

.client-advance {
  h3 {
    margin-top: 0;
    margin-bottom: 1em;
  }
}

.client-stat {
  background-color: $div-color;
  padding: 1em;
  margin-top: 2em;
  border: solid grey 1px;
}