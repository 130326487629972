.research {
  background-color: $div-color;
  padding: 2em;
  border-radius: .8em;
  h3 {
    text-align: center;
    margin-top: .1em;
    margin-bottom: .9em;
  }
}

.research-result {
  background-color: $div-color;
  margin-top: 3em;
  padding: 2em;
  border-radius: .8em;
  margin-bottom: 150px;
  table {
    margin-bottom: 0;
    text-align: center;
    th {
      text-align: center;
      cursor: auto;
    }
    tr {
      &:first-child:hover {
        background-color: $div-color;
      }
      &:hover {
        background-color: #ccff90;
        cursor: pointer;
      }
    }
  }
}