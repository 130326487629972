.invoice-client {
  background-color: $div-color;
  padding: 1em;
  border-radius: .8em;
  border: 1px #CCCCCC solid;
  p {
    margin-bottom: .2em;
  }
}

.bold {
  font-weight: bold;
}

.uppercase {
  text-transform: uppercase;
}