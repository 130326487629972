.navbar {
  background-color: #FFFFFF;
  margin-top: 30px; }
  .navbar .navbar-brand img {
    height: 160%; }
  .navbar li:hover {
    background-color: #ccff90; }

.navbar-after-scroll {
  position: fixed;
  top: 0;
  margin-top: 0;
  opacity: .9;
  z-index: 1; }

.bottom-research-bar {
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1em; }

.form-signin {
  background-color: #FFFFFF;
  padding: 20px;
  border-radius: .8em;
  width: 40%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  .form-signin h2 {
    margin-top: 0px; }
  .form-signin #username {
    margin-bottom: .5em; }

.research {
  background-color: #FFFFFF;
  padding: 2em;
  border-radius: .8em; }
  .research h3 {
    text-align: center;
    margin-top: .1em;
    margin-bottom: .9em; }

.research-result {
  background-color: #FFFFFF;
  margin-top: 3em;
  padding: 2em;
  border-radius: .8em;
  margin-bottom: 150px; }
  .research-result table {
    margin-bottom: 0;
    text-align: center; }
    .research-result table th {
      text-align: center;
      cursor: auto; }
    .research-result table tr:first-child:hover {
      background-color: #FFFFFF; }
    .research-result table tr:hover {
      background-color: #ccff90;
      cursor: pointer; }

.canceled {
  text-decoration: line-through;
  font-weight: bold; }

.invoice-header {
  height: 6em;
  line-height: 6em; }

.tiny {
  font-size: 10px;
  font-weight: 100; }

.invoice-title {
  margin-top: 0;
  margin-left: 1.3em; }

.invoice-body {
  background-color: #FFFFFF;
  margin-top: 2em;
  padding: 2em;
  border-radius: .8em; }

.invoice-column-head p {
  font-weight: bold;
  font-size: 1.3em; }

.search-button {
  margin-top: 1em; }

.vertical-center {
  height: 2em;
  display: flex;
  justify-content: center;
  flex-direction: column; }

.title_invoiceline {
  font-size: 1.2em;
  vertical-align: top;
  color: #72b4a3; }

.invoice-classic {
  margin-left: 3.5em; }

.advance-line {
  margin-bottom: .6em; }

.invoice-description {
  margin-left: 6em;
  width: 95%; }

.invoice-total {
  background-color: #FFFFFF;
  margin-top: 1em;
  margin-bottom: 4em;
  padding: 2em;
  border-radius: .8em; }

.invoice-edit-pc-deadline {
  margin-top: 1em; }

.sortable-element {
  background-color: #FFFFFF;
  border-radius: .8em;
  padding: .5em; }

.add-element {
  cursor: pointer !important;
  margin-right: 0.5em; }

.sort-cursor {
  display: inline-block;
  float: left; }
  .sort-cursor:hover {
    cursor: row-resize; }

.placeholder {
  background-color: #ccff90;
  border-radius: .8em;
  height: 60px;
  -webkit-box-shadow: 0px 0px 10px #888;
  -moz-box-shadow: 0px 0px 10px #888;
  box-shadow: 0px 0px 10px #888; }

.invoice-advance {
  margin-top: 3em; }

[contenteditable="true"] {
  padding: .3em;
  border: 1px solid grey;
  background-color: #eceff1;
  border-radius: .2em;
  min-height: 2em; }
  [contenteditable="true"]:hover {
    cursor: text; }

.notification {
  display: inline-block;
  color: red; }

.space-left {
  margin-left: 2em; }

.is-send {
  background-color: #58C9B9; }

.invoice-table td {
  vertical-align: middle !important; }

.form-inline {
  display: inline; }

.search-info {
  border-radius: .8em;
  padding: 0.8em;
  margin-top: 15px;
  background-color: #FFFFFF; }

.clickable-row {
  min-width: 80px; }

.invoice-clickable-row {
  min-width: 80px; }

.invoice-settlement .edit-settlement {
  vertical-align: top; }

.invoice-client {
  background-color: #FFFFFF;
  padding: 1em;
  border-radius: .8em;
  border: 1px #CCCCCC solid; }
  .invoice-client p {
    margin-bottom: .2em; }

.bold {
  font-weight: bold; }

.uppercase {
  text-transform: uppercase; }

body {
  background-color: red; }

.client-header {
  float: none;
  margin: 0 auto;
  padding: 1em;
  background-color: #566270;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: .8em; }
  .client-header h4 {
    color: #FFFFF3;
    font-weight: bold; }

.client-tva-edit {
  background-color: #F1F1F1; }

.client-advance h3 {
  margin-top: 0;
  margin-bottom: 1em; }

.client-stat {
  background-color: #FFFFFF;
  padding: 1em;
  margin-top: 2em;
  border: solid grey 1px; }

.client-details {
  background-color: #FFFFFF;
  margin-top: 2em;
  padding: 2em;
  border-radius: .8em; }
  .client-details .client-details-title {
    font-weight: bold;
    margin-bottom: .1em; }

.global-item {
  background-color: #FFFFFF;
  padding: 2em;
  border-radius: .8em;
  margin-bottom: 1em; }

.color-bubble {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%; }

* {
  box-sizing: border-box; }

html {
  height: 100%; }

body {
  background-color: #F1F1F1;
  height: 100%;
  margin: 0;
  padding: 0; }

#piechart {
  height: 300px; }

.grey {
  color: grey; }

.red {
  color: red; }

.green {
  color: green; }

.custom-alert-message {
  position: fixed;
  display: table;
  table-layout: fixed;
  z-index: 1;
  width: 300px;
  right: -400px;
  top: 50px;
  margin-bottom: 0;
  overflow: auto;
  word-wrap: break-word;
  opacity: 0;
  transition: right 700ms ease 250ms, opacity 700ms ease; }
  .custom-alert-message.show {
    right: 10px;
    opacity: 1;
    transition: right 700ms ease, opacity 700ms ease 250ms; }
  .custom-alert-message .icon, .custom-alert-message .text {
    display: table-cell;
    vertical-align: middle;
    width: 100%; }
  .custom-alert-message .icon {
    width: 25px;
    padding-right: 10px;
    font-size: 25px; }
    .custom-alert-message .icon:before {
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: normal;
      vertical-align: middle; }
  .custom-alert-message.alert-success .icon:before {
    content: "\e089"; }
  .custom-alert-message.alert-danger .icon:before {
    content: "\e088"; }
  .custom-alert-message.alert-info .icon:before {
    content: "\e086"; }
  .custom-alert-message.alert-warning .icon:before {
    content: "\e107"; }

.turnover-chart-container {
  background-color: #FFFFFF;
  padding: 2em;
  margin-top: 2em;
  margin-bottom: 2em; }

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important; }

.welcome-page-table td {
  vertical-align: middle !important; }
