.navbar {
  background-color: $div-color;
  margin-top: 30px;
  .navbar-brand {
    img {
      height: 160%;
    }
  }
  li {
    &:hover {
      background-color: #ccff90;
    }
  }
}

.navbar-after-scroll {
  position: fixed;
  top: 0;
  margin-top: 0;
  opacity: .9;
  z-index: 1;
}